<template>
  <div class="container text-white py-3">
    <h1 class="text-center fw-bold header">Syarat dan Ketentuan Pay Later</h1>

    <p class="font-normal">
      Sekolah Desain mengedepankan kemudahan bagi para pengguna yang terkendala
      dengan dana disaat ingin belajar bersama kami. Fasilitas PayLater
      memungkinkan Anda untuk membayar pembelian kelas di Sekolah Desain dalam
      jangka waktu tertentu. Untuk dapat menggunakan PayLater, Anda harus
      mengetahui syarat dan ketentuan berikut:
    </p>

    <h2 class="sub-header">SYARAT</h2>
    <ol class="font-normal">
      <li>
        Pengguna PayLater wajib memiliki KTP. Apabila belum memiliki KTP,
        pengguna dapat menggunakan KTP Orangtua/Wali.
      </li>
      <li>Mengisi data diri berupa nama, email, dan nomor telepon</li>
      <li>Mengunggah foto KTP secara close up dan foto (selfie) bersama KTP</li>
      <li>Wajib melunasi tagihan dengan ketentuan waktu yang berlaku</li>
    </ol>

    <h2 class="sub-header">KETENTUAN</h2>
    <ol class="font-normal">
      <li>
        Pengajuan PayLater akan diproses oleh admin dalam kurun waktu maksimal
        1x24 jam. Jika lebih dari itu dan belum terverifikasi, silahkan
        melakukan pengajuan kembali
      </li>
      <li>
        Fasilitas PayLater hanya dapat digunakan untuk 1 transaksi dalam 1
        waktu. Jika telah dilunasi, pengguna dapat mengajuakan PayLater kembali
        untuk transaksi lain.
      </li>
      <li>
        Tagihan PayLater akan diberikan sebanyak 2 kali, dengan waktu tagihan
        yang telah ditentukan management Sekolah Desain.
      </li>
      <li>
        Setiap notifikasi penagihan muncul, akan ada 2 opsi:
        <ul class="pl-3">
          <li>
            <strong class="font-normal">Bayar sekarang</strong> untuk melunasi,
            kemudian kelas akan dilanjutkan dan tidak akan ada lagi penagihan,
            karena telah LUNAS terbayar
          </li>
          <li>
            <strong class="font-normal">Tambah Tenor Tagihan</strong>, yang
            artinya tagihan akan di perpanjang sampai batas penagihan
            berikutnya.
          </li>
        </ul>
      </li>
      <li>
        Apabila pengguna belum membayar hingga H+3 tagihan kedua, maka kelas
        yang dibeli dengan PayLater akan terkunci secara otomatis, hingga
        pengguna melunasi pembayaran kelasnya.
      </li>
    </ol>

    <h2 class="sub-header">Syarat dan Cara Pengambilan Foto KTP</h2>
    <ol class="font-normal">
      <li>
        Mohon upload foto KTP asli (bukan fotokopi) dengan jelas dan terbaca
      </li>
      <li>Posisikan untuk memfoto KTP dalam bentuk landscape</li>
      <li>
        Pastikan pada saat mengambil foto KTP menyesuaikan ukuran frame yang
        telah disediakan, jangan sampai terpotong atau berada diluar frame.
      </li>
      <li>Posisikan foto selfie bersama KTP dalam bentuk landscape.</li>
      <li>
        Pastikan pada saat foto selfie bersama KTP, wajah maupun KTP terlihat
        jelas dan tidak tertutupi apapun.
      </li>
    </ol>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: `SKPaylater`,
})
</script>

<style scoped>
h1,
h2 {
  text-transform: uppercase;
}

h1.header {
  font-size: 1.75rem;
  font-weight: 800;
}

h2.sub-header {
  font-size: 1.35;
  color: #ffffff;
}

.font-normal,
.font-normal li {
  font-size: 1.15rem;
}
</style>
